<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.files") }}</h1>
    <breadcrumb class="mt-3 mb-4" />

    <div class="card mb-4">
      <custom-table
        :fields="fields"
        :items="files"
        :loading="waiting"
        :pagination="true"
        :perPage="10"
        :searchable="true"
        :searchFields="searchFields"
        :showHeader="true"
        sortBy="filename"
        :sortFields="sortFields"
        @row-click="(id) => openFile(id)"
      >
        <template #cell(createdAt)="{ value }">
          {{ getLocaleDate(locale, value) }}
        </template>
        <template #cell(updatedAt)="{ value }">
          {{ getLocaleDate(locale, value) }}
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import CustomTable from "../components/table/CustomTable.vue";
import { getLocaleDate } from "../utils/dateUtils";

export default {
  components: { Breadcrumb, CustomTable, FormButton },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { locale, t } = useI18n();

    const estate = computed(() => store.state.estate.estate);
    const files = computed(() => store.state.file.files);
    const file = computed(() => store.state.file.file);

    const waiting = computed(() => {
      if (store.state.estate.waiting) return true;
      if (store.state.file.waiting) return true;
      return false;
    });

    const fields = ref([
      {
        key: "name",
        label: "name",
      },
      {
        key: "filename",
        label: "filename",
      },
    ]);
    const searchFields = ref(["createdAt", "filename"]);
    const sortFields = ref(["filename", "createdAt"]);

    const openFile = (id) => {
      const file = files.value.find((file) => file.id == id);
      if (file) {
        store.dispatch("file/getFile", file.path);
      }
    };

    onMounted(() => {
      if (!files.value.length) {
        store.dispatch("file/getFiles", estate.value.id);
      }
    });

    watch(
      () => file.value,
      (file) => {
        if (file) {
          window.open(file, "_blank");
        }
      }
    );

    return {
      fields,
      files,
      router,
      getLocaleDate,
      locale,
      openFile,
      searchFields,
      sortFields,
      t,
      waiting,
    };
  },
};
</script>
